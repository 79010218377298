// react
import React from "react";
import {Dropdown} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import {NavigateFunction} from "react-router-dom";
import {Tooltip} from "react-tooltip";

// local
import {
	clients,
	Client,
	Department,
	PageProps,
	withNavigation,
	User,
	System,
	PageNav,
	FreshdeskTicket
} from "../types";
import {
	Footer,
	Loading,
	Header,
	AppSidebar,
	AppSidebarIcon,
	AppMenu,
	AppSettings,
	SupportSettings
} from "../components";
import {
	ClientInfoForm,
	ClientDetailsForm,
	SupportMondayForm,
} from "../forms";
import {
	SalesSection,
	CoursesSection,
	CouponsSection,
	EngagementSection,
	InsightsSection,
	LocationSection,
	SurveySection,
	StudentsSection
} from "../sections";

/**
 * Support Header
 */

interface SubheaderProps {
	navigate: NavigateFunction,
	user: User,
	client: Client,
	departments: Department[],
	departmentId: string,
	schoolId: number
}

const Subheader = (props: SubheaderProps) => {

	// setup
	const {user, client, departments, departmentId} = props;
	const editSchool = client.url.includes('aaadrivingcourse');
	const editDepartment = client.url.includes('vesteap') && departments.length > 1 && !editSchool;

	const onChangeClient = async (eventKey: any) => {
		// setup
		const clientId = eventKey;
		const {client} = props;

		// Update the settings.
		await client.setClient(clientId);
	}

	const onChangeDepartment = async (eventKey: any) => {
		// Update the settings.
		await client.setDepartment(eventKey);
	}

	const onChangeSchool = async (eventKey: any) => {
		// setup
		const clientId = eventKey;
		const {client} = props;

		// Update the settings.
		await client.setClient(clientId);
	}

	const renderClients = (clientName: string | null, userClientId: string | null) => {
		// setup
		const eClients = clients.mapParentItems(userClientId, user.clients);

		// render
		if (eClients.length) {
			return <Dropdown className="clients" onSelect={onChangeClient}>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					{clientName ? clientName : "Choose a client"}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{eClients}
				</Dropdown.Menu>
			</Dropdown>
		} else {
			return <div className='subheader-title'>{clientName}</div>;
		}
	}

	const renderDepartments = (departments: Department[], departmentId: string | null) => {
		// setup
		let eDepartments = departments.map(item => {
			const key = 'department-'+item.id;
			return (<Dropdown.Item key={key} eventKey={item.id}>
				{item.name}
				</Dropdown.Item>
			)});
		eDepartments.unshift(<Dropdown.Item id={'all'} key={'all'} eventKey={'All Departments'}>
			All Departments
		</Dropdown.Item>)

		// render
		return <Dropdown className="departments" onSelect={onChangeDepartment}>
			<Dropdown.Toggle variant="success" id="dropdown-basic">
				{departmentId ? departmentId : "Choose a department"}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{eDepartments}
			</Dropdown.Menu>
		</Dropdown>
	}

	const renderSchools = (companyId: number) => {
		// setup
		let eSchools = clients.mapSchoolItems(companyId);
		if (!eSchools.length) {
			return null;
		}
		eSchools.unshift(<Dropdown.Item id={'all'} key={'all'} eventKey={companyId}>
			All Schools
		</Dropdown.Item>)

		return <Dropdown className="departments" onSelect={onChangeSchool}>
			<Dropdown.Toggle variant="success" id="dropdown-basic">
				{"Choose a school"}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{eSchools}
			</Dropdown.Menu>
		</Dropdown>
	}

	// render
	const clientName = (client && client.name) ? client.name : "";
	const userClientId = user.isAdmin() ? null : user.clientId;

	return (
		<div className="subheader">
			{renderClients(clientName, userClientId)}
			{editSchool && renderSchools(client.id)}
			{editDepartment && renderDepartments(departments, departmentId)}
		</div>
	);

}

/**
 * ReportsPage
 */

interface ReportsProps extends PageProps {
}
interface ReportsState {
	loaded: boolean,
	showDetailsForm: string | null,
	showInfoForm: string | null,
	showFeatureForm: boolean,
	showIssueForm: boolean,
	showSupportForm: FreshdeskTicket | null
}

@inject("system")
@inject("client")
@inject("user")
@observer
export class ReportsPageComponent extends React.Component<ReportsProps, ReportsState> {

	constructor(props: ReportsProps) {
		// setup
		super(props);

		this.state = {
			loaded: false,
			showDetailsForm: null,
			showInfoForm: null,
			showFeatureForm: false,
			showIssueForm: false,
			showSupportForm: null
		}

		// Register the callbacks.
		this.onChangeClient = this.onChangeClient.bind(this);
		this.onLogout = this.onLogout.bind(this);
		this.onNavigateEngagements = this.onNavigateEngagements.bind(this);
		this.onToggleDetailsForm = this.onToggleDetailsForm.bind(this);
		this.onToggleInfoForm = this.onToggleInfoForm.bind(this);
		this.onToggleFeatureForm = this.onToggleFeatureForm.bind(this);
		this.onToggleIssueForm = this.onToggleIssueForm.bind(this);
		this.onToggleSupportForm = this.onToggleSupportForm.bind(this);
		this.onToggleSection = this.onToggleSection.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {client, user, navigate} = this.props;

		// Get the client list and possibly client info.
		if (!client.id || !user.clientId) {
			user.logout();
			navigate('/login');
		}

		// Update the state.
		this.setState({loaded: true});
	}

	onChangeClient = async (eventKey: any) => {
		// setup
		const clientId = eventKey;
		const {client} = this.props;

		// Update the settings.
		await client.setClient(clientId);
	}

	onNavigateEngagements() {
		// setup
		const {navigate} = this.props;

		// Go to the engagements page.
		navigate("/engagements");
	}

	onLogout() {
		// setup
		const {navigate, user, client} = this.props;

		// Logout and go to the login page.
		user.logout();
		client.logout();
		navigate("/login");
	}

	onToggleSection(section: string) {
		// setup
		const {client} = this.props;
		const sort = client.pageNavigation;

		// Show states
		sort.toggle(section);
	}

	onToggleDetailsForm() {
		this.setState({showDetailsForm: this.state.showDetailsForm ? null : 'edit'});
	}

	onToggleFeatureForm() {
		this.setState({showFeatureForm: !this.state.showFeatureForm});
	}

	onToggleInfoForm() {
		this.setState({showInfoForm: this.state.showInfoForm ? null : 'edit'});
	}

	onToggleIssueForm() {
		this.setState({showIssueForm: !this.state.showIssueForm});
	}

	onToggleSupportForm(showSupportForm: FreshdeskTicket | null) {
		this.setState({showSupportForm});
	}

	render() {
		// setup
		const {client, user, navigate, system} = this.props;
		const {loaded, showInfoForm, showDetailsForm, showIssueForm, showFeatureForm} = this.state;
		if (!loaded) {
			return <Loading className='vt-center'/>
		}

		// render
		return (
			<div className="page">
				<Tooltip id="lct-reporting-tooltip"/>
				<Header client={client}>
					<AppMenu user={user} onLogout={this.onLogout}/>
				</Header>

				<div id="ReportsPage-body" className="body">
					{ this.renderSidebar(user, client, navigate, client.pageNavigation) }
					{ this.renderSections(user, client, navigate, system, client.pageNavigation) }
				</div>

				<Footer/>

				{ /* popup forms */ }
				{showInfoForm && <ClientInfoForm
					clientId={client.id}
					action={showInfoForm}
					close={this.onToggleInfoForm}
				/>}
				{showDetailsForm && <ClientDetailsForm
					clientId={client.id}
					action={showDetailsForm}
					close={this.onToggleDetailsForm}
				/>}
				{showIssueForm && <SupportMondayForm
					client={client}
					title='Report an issue'
					board={client.issuesBoard}
					options={client.issuesOptions}
					close={this.onToggleIssueForm}
				/>}
				{showFeatureForm && <SupportMondayForm
					client={client}
					title="Make a Project Request"
					board={client.featuresBoard}
					options={client.featuresOptions}
					close={this.onToggleFeatureForm}
				/>}
			</div>
		);
	}

	renderSidebar(user: User, client: Client, navigate: NavigateFunction, show: PageNav) {
		return (
			<AppSidebar>
				{show.settings && user.isCompanyManager() && <AppSettings
					navigate={navigate}
					client={client}
					onEditClientInfo={this.onToggleInfoForm}
					onEditClientDetails={this.onToggleDetailsForm}
					onShowFeatureForm={this.onToggleFeatureForm}
					onShowIssueForm={this.onToggleIssueForm}
				/>}
				{show.support && <SupportSettings
					navigate={navigate}
					clientName={client.name}
					onShowFeatureForm={this.onToggleFeatureForm}
					onShowIssueForm={this.onToggleIssueForm}
				/>}
				<AppSidebarIcon
					section='sales'
					text='Sales'
					state={show.sales}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='engagement'
					text='Engagement'
					state={show.engagement}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='location'
					text='Summary'
					state={show.location}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='students'
					text='Students'
					state={show.students}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='courses'
					text='Courses'
					state={show.courses}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='coupons'
					text='Codes'
					state={show.coupons}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='survey'
					text='Surveys'
					state={show.survey}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='insights'
					text='Insights'
					state={show.insights}
					onSelect={this.onToggleSection}
				/>
			</AppSidebar>
		);
	}

	renderSections(user: User, client: Client, navigate: NavigateFunction, system: System, show: PageNav) {
		// setup
		const isManager = user.isManager();
		const showDepartments = (client.departments.length > 1);

		// render
		return (
			<div className='section-group'>
				{(isManager || showDepartments) && <Subheader
					user={user}
					client={client}
					departments={client.departments}
					departmentId={client.departmentId}
					schoolId={client.schoolId}
					navigate={navigate}
				/>}
				<div className="scroll-y">
					{show.engagement === 'selected' &&
						<EngagementSection
							user={user}
							client={client}
							navigate={navigate}
							system={system}
						/>
					}
					{show.location === 'selected' &&
						<LocationSection
							client={client}
						/>
					}
					{show.sales === 'selected' &&
						<SalesSection
							user={user}
							client={client}
							navigate={navigate}
						/>
					}
					{show.students === 'selected' &&
						<StudentsSection
							user={user}
							client={client}
							navigate={navigate}
						/>
					}
					{show.coupons === 'selected' &&
						<CouponsSection
							user={user}
							client={client}
							navigate={navigate}
						/>
					}
					{show.courses === 'selected' &&
						<CoursesSection
							user={user}
							client={client}
							navigate={navigate}
						/>
					}
					{show.survey === 'selected' &&
						<SurveySection
							client={client}
							navigate={navigate}
						/>
					}
					{show.insights === 'selected' && user.isCompanyManager() &&
						<InsightsSection
							user={user}
							client={client}
						/>
					}
				</div>
			</div>
		);
	}

}

export const ReportsPage = withNavigation(ReportsPageComponent);
