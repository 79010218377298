/* For reports and recharts */
const date = new Date();
export const CURRENT_YEAR = date.getFullYear();
export const CURRENT_MONTH = date.getMonth() + 1;

export const UTC_DATE_FORMAT = 'YYYY-MM-DD';
export const LOC_DATE_FORMAT = 'MM/DD/YY';
export const LOC_SDATE_FORMAT = 'MM/DD';

/* styles */
export const CHART_TOOLTIP_STYLE = {padding: '2px 10px', backgroundColor: '#fff', borderRadius: '8px', border: '1px solid #8b61ff'};
export const LEGEND_STYLE = {display: "inline-block"};
export const TOOLTIP_STYLE = {background: "red", opacity: 1};
export const TOOLTIP_DELAY = 1000;

// regex
export const REGX_BR = /(<br>|<\/br>|<br\/>|<br \/>)/mgi
export const REGX_NEWLINE = /(\r\n|\n|\r|\\n)/gm
export const REGEX_HTML_TAG = /(<([^>]+)>)/gi
export const REGEX_SPACE = / /g

/* Roles */
export const ROLE_ADMIN = 'admin';
export const ROLE_ACCOUNT_MANAGER = 'accountmanager';
export const ROLE_COMPANY_MANAGER = 'companymanager';
export const ROLE_DEPARTMENT_MANAGER = 'departmentmanager';
export const ROLE_USER = 'user';

/* Storage */
export const LOCAL_STORAGE = "local";
export const SESSION_STORAGE = "session";

export const LOGIN_SETTINGS = "lct-report.login";
export const TOOL_SETTINGS = "lct-report.tool";

/* Other */
export const SIDEBAR_ICON_SIZE = 28;
export const APPMENU_ICON_SIZE = 22;

/* For Google */
//const GOOGLE_TRACKING_ID = "UA-XXXXX-X"; // OUR_TRACKING_ID

/* For Freshdesk */
export const API_FRESHDESK_PAGE_SIZE = 25;
export const API_FRESHDESK_URL = 'https://blunovus.freshdesk.com';
export const API_FRESHDESK_KEY = 'ljgL61qbzcvkhFAYk5bv';

/* For Monday.com */
export const API_MONDAY_URL = 'https://api.monday.com/v2';
export const API_MONDAY_AUTH = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjI2NTI5ODE1MSwiYWFpIjoxMSwidWlkIjo0NTAyMDM0NCwiaWFkIjoiMjAyMy0wNi0yN1QxNDowNDoxOC4wMzhaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTc1NzA5ODIsInJnbiI6InVzZTEifQ.AYQDtLJJ87eGY1Bzfpi5RHnjzUetlnVCSVioau4fceA';
export const API_MONDAY_ID = '1204669582128746';
export const API_MONDAY_SECRET = '9ea3bbc3390a0f898b51a9b729db7dbc';
